<template>
    <div class="page-warp min-page invoice-page gray-page">
        <van-form v-model="submitForm" ref="submitForm" @submit="submitSave">
            <van-cell :title="sessionData.title" />
            <div class="row page-model-title">1/6发票类型
                <!-- <span @click="toDetail">编辑</span> required -->
            </div>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="submitForm.invoiceTypeName"
                label="发票类型"
                placeholder="请选择发票类型"
                @click="showLX = true"
                />
            <van-popup v-model="showLX" position="bottom">
                <van-picker
                    show-toolbar
                    :item-height="'1.32rem'"
                    :columns="invoiceLoop"
                    @confirm="onConfirmLX"
                    @cancel="showLX = false"
                />
            </van-popup>
            <div class="row page-model-title">2/6发票抬头</div>
            <van-field
                v-model="submitForm.invoiceName"
                label="单位名称"
                placeholder="请输入公司名称"/>
            <div class="row search-file-res" v-if="searchBusinList.length>0 && searchBusBox">
                <div class="row search-file-box">
                    <div class="row search-f-item" 
                        v-for="(item,index) in searchBusinList" :key="index"
                        @click="queryIvoiceNo(item)">
                        <span>{{item.companyname}}</span>
                        <span>{{item.creditno}}</span>
                    </div>
                </div>
                <div class="row search-f-close" @click="searchBusBox = false"><van-icon name="close" /></div>
            </div>
            <van-field
                v-model="submitForm.invoiceTaxpayerNo"
                label="税号"
                placeholder="请输入税号"/>
            <van-field
                v-model="submitForm.invoiceAddress"
                label="单位地址"
                placeholder="请输入单位地址"/>
            <van-field
                v-model="submitForm.invoicePhone"
                label="电话"
                placeholder="请输入电话"/>
            <van-field
                v-model="submitForm.invoiceBank"
                label="开户银行"
                placeholder="请输入开户银行"/>
            <van-field
                v-model="submitForm.invoiceCardNo"
                label="开户账号"
                placeholder="请输入开户账号"/>
            <div class="row page-model-title">3/6开票内容</div>
            <van-field
                    v-model="submitForm.invoiceClassName"
                    label="开票项目"
                    placeholder="请输入开票项目"/>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="submitForm.isInvoiceDetailName"
                label="开票明细"
                placeholder="请选择是否有开票明细"
                @click="showKPMX = true"
                />
            <van-popup v-model="showKPMX" position="bottom">
                <van-picker
                    show-toolbar
                    :item-height="'1.32rem'"
                    :columns="isInvoiceDetailLoop"
                    @confirm="onConfirmKPMX"
                    @cancel="showKPMX = false"
                />
            </van-popup>
            <!--<van-field
                readonly
                clickable
                name="picker"
                v-model="submitForm.invoiceClassName"
                label="开票项目"
                placeholder="请选择开票项目"
                @click="showXM = true"
                />
            <van-popup v-model="showXM" position="bottom">
                <van-picker
                    show-toolbar
                    :item-height="'1.32rem'"
                    :columns="invoiceClassLoop"
                    @confirm="onConfirmXM"
                    @cancel="showXM = false"
                />
            </van-popup>-->
            <van-field
                readonly
                v-model="invoiceRateText"
                label="税率"
                placeholder="请输入税率" />
            <van-field
                v-model="submitForm.productCount"
                label="数量"
                @change="updateFeeAmount"
                placeholder="请输入数量"/>
            <van-field
                v-model="submitForm.afterTaxFeeUnit"
                @change="updateFeeAmount"
                label="含税单价"
                placeholder="请输入含税单价"/>
            <van-field
                v-model="submitForm.productSpecification"
                label="规格型号"
                placeholder="请输入规格型号"/>
            <van-field
                v-model="submitForm.productUnit"
                label="计算单位"
                placeholder="请输入计算单位"/>
            <van-field
                readonly
                v-model="submitForm.afterTaxFeeAmount"
                label="含税总额"
                placeholder="单价*数量"/>
            <van-field
                readonly
                v-model="submitForm.taxFeeAmount"
                label="税额"
                placeholder="含税总额/1.03*税率"/>
            <van-field
                readonly
                v-model="submitForm.serviceFee"
                label="平台服务费"
                placeholder="请输入平台服务费"/>
            <div class="row page-model-title">4/6送票选择</div>
            <van-field
                readonly
                clickable
                name="picker"
                v-model="submitForm.mailWayName"
                label="发票递送方式"
                placeholder="请选择发票递送方式"
                @click="showMW = true"
                />
            <van-popup v-model="showMW" position="bottom">
                <van-picker
                    show-toolbar
                    :item-height="'1.32rem'"
                    :columns="mailWayLoop"
                    @confirm="onConfirmMW"
                    @cancel="showMW = false"
                />
            </van-popup>
            <van-cell 
                @click="toAddressDetail()"
                v-if="submitForm.mailWay == 2"
                is-link
                :title="submitForm.recipientAddress?submitForm.recipientAddress : '选择收件信息'"
                :label="submitForm.recipientName+'     '+submitForm.recipientPhone" />
            <div class="row fxed-bottom">
                <span class="f-total">合计：¥{{jisuanN()}}</span>
                <span class="f-sub" @click="submitSave">提交订单</span>
            </div>
        </van-form>
    </div>
</template>
<script>
import { rejectIndividualInvoi,searchBusinessQuery } from '@/api/customer'
import { invoiceType,invoiceClass,mailWay,isInvoiceDetail } from '@/api/dic'
import { checkNumbers } from '../../assets/js/comm'
import JSMath from "../../utils/jsMath";
export default {
    name:'toInvoice',
    data() {
        return {
            loading: false,
            showLX:false,
            invoiceList: [],
            invoiceLoop:[],

            showXM:false,
            showKPMX:false,
            invoiceClassList: [],
            isInvoiceDetailList: [],
            invoiceClassLoop:[],
            isInvoiceDetailLoop:[],

            showMW:false,
            mailWayList:[],
            mailWayLoop:[],
            submitForm:{
                customerName:'',
                customerId:'',
                invoiceType:'',
                invoiceTypeName:'',
                productUnit:'',
                invoiceTaxpayerNo:'',
                invoiceAddress:'',
                invoicePhone:'',
                invoiceBank:'',
                invoiceCardNo:'',
                invoiceClass:'',
                invoiceClassName:'',
                invoiceRate:'',
                productCount:'',
                afterTaxFeeUnit:'',
                productSpecification:'',
                productUnit:'',
                afterTaxFeeAmount:'',
                taxFeeAmount:'',
                serviceFee:'',
                mailWay:'',
                mailWayName:'',
                recipientAddress:'',
                recipientName:'',
                recipientPhone:'',
            },
            searchBusinList:[],
            searchClickRows:true,
            searchBusBox:true,
            invoiceRateText:'',
            sessionData:{
                id:'',
                title:'',
                customerServiceFeeRate:'',
                checkAddress:{}
            },
        };
    },
    watch:{
        "submitForm.invoiceName":{
            handler(nv,ov){
                if(nv != ov && nv){
                    if(!this.searchClickRows){
                        this.searchClickRows = true;
                        return true;
                    }
                    this.searchBusBox = true;
                    this.searchIvoceNo();
                }else if(!nv){
                    this.searchBusinList = []
                }
            }
        }
    },
    created(){
        this.$nextTick(()=>{
            this.getInvoiceType();
            this.getInvoiceClass();
            this.getIsInvoiceDetail();
            this.getMailWay();
        })
    },
    mounted(){
        let subF = sessionStorage.getItem('invoiceSubForm',JSON.stringify(this.submitForm));
        if(subF){
            this.searchClickRows = false;
            this.submitForm = JSON.parse(subF);
            this.submitForm.invoiceRate = this.submitForm.invoiceType == 1 ? 0.03:0
            this.invoiceRateText = this.submitForm.invoiceType == 1 ? '3%':'0%'
            // this.updateTaxFeeAmount();
        }
        this.$nextTick(()=>{
            let sessionData = sessionStorage.getItem('invoiceDetail');
            this.sessionData = sessionData ? JSON.parse(sessionData) : {};
            if(this.sessionData.id){
                this.submitForm.customerId = this.sessionData.id;
                this.submitForm.customerName = this.sessionData.title;
            }
            this.$nextTick(()=>{
                if(subF){
                    this.initUserAddress();
                    this.submitForm.serviceFee = this.mul(Number(this.submitForm.afterTaxFeeAmount),Number(this.sessionData.customerServiceFeeRate)).toFixed(2);
                }
            })

        })
    },
    methods: {
        jisuanN(){
            return JSMath(Number(this.submitForm.taxFeeAmount)).add(Number(this.submitForm.serviceFee)).value
        },
        searchIvoceNo(){
            if(this.submitForm.invoiceName){
                searchBusinessQuery({keyword:this.submitForm.invoiceName}).then(res => {
                    this.searchBusinList = res.data;
                });
            }else{
                this.searchBusinList = []
            }
        },
        queryIvoiceNo(row){
            this.searchClickRows = false;
            this.searchBusBox = false;
            this.submitForm.invoiceName = row.companyname
            this.submitForm.invoiceTaxpayerNo = row.creditno;
        },
        updateFeeAmount(){
            if(this.submitForm.afterTaxFeeUnit > 0 && this.submitForm.productCount > 0){
                this.submitForm.afterTaxFeeAmount = this.mul(Number(this.submitForm.afterTaxFeeUnit),Number(this.submitForm.productCount)).toFixed(2);
                this.updateTaxFeeAmount();
            }
        },
        updateTaxFeeAmount(){
            if(this.submitForm.afterTaxFeeAmount > 0){
                if(this.submitForm.invoiceRate && this.submitForm.invoiceRate == 0.03){
                    this.submitForm.taxFeeAmount = this.mul(Number(this.submitForm.afterTaxFeeAmount)/1.03,0.03).toFixed(2);
                }else{
                    this.submitForm.taxFeeAmount = 0
                }
                this.submitForm.serviceFee = this.mul(Number(this.submitForm.afterTaxFeeAmount),Number(this.sessionData.customerServiceFeeRate)).toFixed(2);
            }
        },
        initUserAddress(){
            let jsonAdd = this.sessionData.checkAddress ? this.sessionData.checkAddress : '';
            if(jsonAdd){
                this.submitForm.recipientAddress = jsonAdd.recipientAddress;
                this.submitForm.recipientName = jsonAdd.recipientName;
                this.submitForm.recipientPhone = jsonAdd.recipientPhone;    
            }
        },
        getInvoiceType(){
            invoiceType().then(res => {
                this.invoiceList = res.data;
                this.invoiceLoop = [];
                res.data.map(item=>{
                    this.invoiceLoop.push(item.dictLabel)
                })
            });
        },
        onConfirmLX(value,index){
            this.submitForm.invoiceTypeName = value;
            this.submitForm.invoiceType = this.invoiceList[index].dictValue;
            this.showLX = false;
            this.submitForm.invoiceRate = this.submitForm.invoiceType == 1 ? 0.03:0
            this.invoiceRateText = this.submitForm.invoiceType == 1 ? '3%':'0%'
            this.updateTaxFeeAmount();
        },
        getInvoiceClass(){
            invoiceClass().then(res => {
                this.invoiceClassList = res.data;
                this.invoiceClassLoop = [];
                res.data.map(item=>{
                    this.invoiceClassLoop.push(item.dictLabel)
                })
            });
        },
        onConfirmXM(value,index){
            this.submitForm.invoiceClassName = value;
            this.submitForm.invoiceClass = this.invoiceClassList[index].dictValue;
            this.showXM = false;
        },
        getIsInvoiceDetail(){
            isInvoiceDetail().then(res => {
                this.isInvoiceDetailList = res.data;
                this.isInvoiceDetailLoop = [];
                res.data.map(item=>{
                    this.isInvoiceDetailLoop.push(item.dictLabel)
                })
            });
        },
        onConfirmKPMX(value,index){
            this.submitForm.isInvoiceDetailName = value;
            this.submitForm.isInvoiceDetail = this.isInvoiceDetailList[index].dictValue;
            this.showKPMX = false;
        },
        getMailWay(){
            mailWay().then(res => {
                this.mailWayList = res.data;
                this.mailWayLoop = [];
                res.data.map(item=>{
                    this.mailWayLoop.push(item.dictLabel)
                })
            });
        },
        onConfirmMW(value,index){
            this.submitForm.recipientAddress = '';
            this.submitForm.recipientName = '';
            this.submitForm.recipientPhone = '';
            this.submitForm.mailWayName = value;
            this.submitForm.mailWay = this.mailWayList[index].dictValue;
            this.showMW = false;
            if(this.submitForm.mailWay == 2) this.initUserAddress();
        },
        submitSave(){
            if(!this.submitForm.invoiceType){
                this.$toast.fail('发票类型不能为空');
                return false;
            }
            if(!this.submitForm.invoiceName){
                this.$toast.fail('单位名称不能为空');
                return false;
            }
            if(!this.submitForm.invoiceTaxpayerNo){
                this.$toast.fail('税号不能为空');
                return false;
            }
            if(!this.submitForm.invoiceClassName){
                this.$toast.fail('开票项目不能为空');
                return false;
            }
            if(!this.submitForm.isInvoiceDetail){
                this.$toast.fail('请选择是否有开票明细');
                return false;
            }
            if(this.submitForm.invoiceType==1 && !this.submitForm.invoiceRate){
                this.$toast.fail('税率不能为空');
                return false;
            }
            if(!this.submitForm.productCount){
                this.$toast.fail('数量不能为空');
                return false;
            }else if(checkNumbers(this.submitForm.productCount)){
                this.$toast.fail('请输入正确数字');
                return false; 
            }
            if(!this.submitForm.afterTaxFeeUnit){
                this.$toast.fail('含税单价不能为空');
                return false;
            }
            if(!this.submitForm.mailWayName){
                this.$toast.fail('发票递送方式不能为空');
                return false;
            }
            if(this.submitForm.mailWay==2 && !this.submitForm.recipientName){
                this.$toast.fail('请选择收件人信息');
                return false;
            }
            rejectIndividualInvoi(this.submitForm).then(response => {
                this.$toast.success(response.msg);
                this.$router.push({
                    path:'/invoice',
                })
            });
        },
        toDetail(row){
            this.$router.push({
                path:'/invoiceAdd',
                query:{
                    id:row ? row.id : ''
                }
            })
        },
        toAddressDetail(){
            sessionStorage.setItem('invoiceSubForm',JSON.stringify(this.submitForm));
            sessionStorage.setItem('searchClickRows',this.searchClickRows);
            this.$router.push({
                path:'/addressInfoList',
            })
        },
        mul(a, b) {
                var c = 0,
                d = a.toString(),
                e = b.toString();
            try {
                c += d.split(".")[1].length;
            } catch (f) {}
            try {
                c += e.split(".")[1].length;
            } catch (f) {}
            return Number(d.replace(".", "")) * Number(e.replace(".", "")) / Math.pow(10, c);
        }
    },
};
</script>
<style lang="less" scoped>
.invoice-page { padding-bottom:70px;}
.page-model-title {
    text-align: left; line-height: 40px; font-size: 14px; color: #080808;
    box-sizing: border-box; padding:0px 10px;
    span {
        color: #278BF9; float: right;
    }
}
.fxed-bottom {
    position: fixed;
    bottom:0px; left: 0px;
    height: 50px; background: #F4F6FA; border-top:1px solid#ccc;
    span {
        display: inline-block; height: 50px; line-height: 50px; font-size: 16px;
        box-sizing: border-box;
    }
    .f-total {
        width: 65%;float: left;
        background: rgba(39, 139, 249, 0.06); color:#000;
        padding-left:10px;
    }
    .f-sub {
        width: 35%; float: right;
        background: #278BF9; color:#fff;
        text-align: center;
    }
}
</style>