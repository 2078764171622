class cal {
    constructor(value) {
        this.value = value
    }

    from(value) {
        // 支持JSMath参数传递主要是用于嵌套的调用
        if ((typeof(value) == 'object') && (value.value != undefined)) {
            this.value = value.value;
        } else {
            this.value = value;
        }
        return this;
    }

    // 加法
    add(value) {
        var thisValueString = this.value.toString();
        var valueString = value.toString();
        var timesCount1 = 0;
        var timesCount2 = 0;
        if (thisValueString.indexOf('.') > 0) {
            timesCount1 = thisValueString.split('.')[1].length;
        }
        if (valueString.indexOf('.') > 0) {
            timesCount2 = valueString.split('.')[1].length;
        }
        var maxtimeCount = timesCount1 > timesCount2 ? timesCount1 : timesCount2;
        this.value = ((Math.pow(10, maxtimeCount) * this.value + Math.pow(10, maxtimeCount) * value)) / Math.pow(10, maxtimeCount);
        return this;
    }

    // 减法
    sub(value) {
        var thisValueString = this.value.toString();
        var valueString = value.toString();
        var timesCount1 = 0;
        var timesCount2 = 0;
        if (thisValueString.indexOf('.') > 0) {
            timesCount1 = thisValueString.split('.')[1].length;
        }
        if (valueString.indexOf('.') > 0) {
            timesCount2 = valueString.split('.')[1].length;
        }
        var maxtimeCount = timesCount1 > timesCount2 ? timesCount1 : timesCount2;
        this.value = ((Math.pow(10, maxtimeCount) * this.value - Math.pow(10, maxtimeCount) * value)) / Math.pow(10, maxtimeCount);
        return this;
    }

    // 除法
    div(value) {
        var thisValueString = this.value.toString();
        var valueString = value.toString();
        var timesCount1 = 0;
        var timesCount2 = 0;
        if (thisValueString.indexOf('.') > 0) {
            timesCount1 = thisValueString.split('.')[1].length;
        }
        if (valueString.indexOf('.') > 0) {
            timesCount2 = valueString.split('.')[1].length;
        }
        var maxtimeCount = timesCount1 > timesCount2 ? timesCount1 : timesCount2;
        this.value = ((Math.pow(10, maxtimeCount) * this.value) / (Math.pow(10, maxtimeCount) * value));
        return this;
    }

    // 乘法
    times(value) {
        var thisValueString = this.value.toString();
        var valueString = value.toString();
        var timesCount1 = 0;
        var timesCount2 = 0;
        if (thisValueString.indexOf('.') > 0) {
            timesCount1 = thisValueString.split('.')[1].length;
        }
        if (valueString.indexOf('.') > 0) {
            timesCount2 = valueString.split('.')[1].length;
        }
        var maxtimeCount = timesCount1 + timesCount2;
        this.value = (Math.pow(10, maxtimeCount) * this.value * Math.pow(10, maxtimeCount) * value) / Math.pow(10, maxtimeCount * 2);
        return this;
    }
}

let JSMath = (value) => {
    let newFn = new cal()
    newFn.from(value)
    return newFn
}

export default JSMath

// 0.1+0.2
// => JSMath(0.1).add(0.2).value == 0.3

// 7*0.8
// => JSMath(7).times(0.8).value == 5.6

// 5.6/7
// => JSMath(5.6).div(7).value = 0.8

// 0.05 + 0.05 + 0.2
// => JSMath(JSMath(0.05).add(0.05)).add(0.2).value == 0.3

// (5+0.6)/7
// => JSMath(JSMath(5).add(0.6)).div(7).value == 0.8