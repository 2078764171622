/*
 * @: 字典
 */
import {get, postJSON } from '@/utils/request'
// 字号字典
export function nameType() {
    return get('/system/dict/data/type/name_type')
}
// 发票类型字典
export function invoiceType() {
    return get('/system/dict/data/type/invoice_type')
}
// 开票项目字典
export function invoiceClass() {
    return get('/system/dict/data/type/invoice_class')
}
// 是否有开票明细
export function isInvoiceDetail() {
    return get('/system/dict/data/type/yes_no')
}
// 发票递送方式字典
export function mailWay() {
    return get('/system/dict/data/type/mail_way')
}